import React, {useState, useEffect} from 'react';
import {Button, Container, Row, Col} from 'react-bootstrap'; 
import PlacesAutoComplete from "./PlacesAutoComplete";
import {useNavigate} from 'react-router-dom';






function PostAddress() {

    const [address, updateAddress] = useState(); 
    const navigate = useNavigate(); 
    const [addressComplete, updateAddressComplete] = useState(false); 
    const [placeId, updatePlaceID] = useState(); 
    
    function onClick() {

        
        fetch("/api/address", { 
            method: "POST", 
            headers: {"Content-Type": "application/json"}, 
            credentials: 'include', 
            withCredentials: true,
            body: JSON.stringify({"address": address, "placeID" : placeId}) 
        }).then((response) => {
            navigate('/home')

        }).catch((err) => { 
            console.log(err) 
        })


    }

    useEffect(() => { 
        

        fetch("/api/login", {
            headers: {"Content-Type": "application/json"},  
            credentials: 'include', 
            withCredentials: true
        }).then((data) => { 
            return data.json() 
        }).then((data) => {
            if (data.SUCCESS === 'false') { 
                throw Error("invalid login")
                
            } else { 
                return fetch("/api/needAddress", {
                    headers: {"Content-Type": "application/json"},  
                    credentials: 'include', 
                    withCredentials: true
                }) 
            }
        }).then((response) => {
            return response.json(); 
        }).then((data) => { 
            if (data.SUCCESS === "true") { 
                navigate('/home')
            }
        }).catch((err) => { 
            navigate('/')

        })
        

    }, [navigate])





    return ( 
        <Container style = {{marginTop: "20%"}}>
            <Row className = "justify-content-center">
                <Col xs = "6" style = {{textAlign: "center"}} >
                    <h1>🛖 Enter Your Address 🛖</h1>
                    <p>Don't worry, this won't be public and will not be used for listings you choose to post (unless you you would like to)! The address needs to be <b>building</b>. The current version of LuggageFree doesn't allow you to modify your address after this, so be careful! </p>
                    <PlacesAutoComplete
                    val = {address}
                    updateVal = {updateAddress}
                    updateAddressComplete = {updateAddressComplete}
                    updatePlaceID = {updatePlaceID}
                />
                <Button style = {{marginTop: "1%"}} onClick = {onClick} disabled = {!addressComplete}>Submit</Button>
                </Col>
            </Row>

        </Container>
    
    
    )
}

export default PostAddress; 