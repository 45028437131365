import Container from 'react-bootstrap/Container'; 
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {useNavigate} from 'react-router-dom';

  


function Navigation() { 
    
    const navigate = useNavigate(); 

    function logOut() { 
        fetch("/api/logOut", {
            headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            }
        }).then(res => { 
            return res.json() 
        }).then(data =>  { navigate('/') })
    }

    return (
        <Navbar expand="lg" className="bg-body-tertiary">
            <Container>
                <Navbar.Brand href="/home">Luggage Free</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="/home">Home</Nav.Link>
                        <Nav.Link href="/listings">View Listings</Nav.Link>
                    </Nav>


                    <Nav className = "xs-auto">
                        <Nav.Link onClick = {logOut}>Log Out</Nav.Link>
                       
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Navigation;



