import React, {useState} from 'react'; 
import {Modal, Button} from 'react-bootstrap'; 
import ListGroup from 'react-bootstrap/ListGroup'
function Booking(props) { 

    const [show, setShow] = useState(false); 

    function handleShow() { 
        if (!show) { 
            setShow(true); 
        }
    }

    function handleClose() { 
        
        setShow(false); 
    }

    return  ( 
        <ListGroup.Item onClick = {handleShow} style = {{textAlign: "center"}} action>
            {props.name[0]} {props.name[1] != null && props.name[1]}
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>🧳 Your LuggageLord: {props.name[0]} {props.name[1] != null && props.name[1]}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                📧 <strong>Email</strong>: {props.email} <br/>
                🛅 <strong>Start Date:</strong> {props.startDate} <br/>
                🛄 <strong>End Date:</strong> {props.endDate} <br/>
                📍<strong>Address: </strong>{props.address}<br/>
                {props.climateControlled && <p style = {{margin: '0'}}>❄️ <b>Climate Controlled</b></p>}
                {props.driveUpAccess &&  <p style = {{margin: '0'}}>🚛 <b>Drive Up Access</b></p>}
                {!props.climateControlled && <p style = {{margin: '0'}}>🚫 <b> No Climate Control</b></p>}
                {!props.driveUpAccess &&  <p style = {{margin: '0'}}>🚶<b>No Drive Up Access</b></p>}
                <br/>

            </Modal.Body>
            <Modal.Footer>
            We'll leave it to you guys from here! Soon, LuggageFree will contain the ability to send chat messages, and a rating system, so stay tuned!
            <Button variant="primary" onClick={handleClose}>
                Close
            </Button>
             </Modal.Footer>
        </Modal>
        </ListGroup.Item>
    )
}

export default Booking; 