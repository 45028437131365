import React, {useEffect, useState} from 'react'; 
import {useNavigate} from 'react-router-dom'
import Navigation from './Navbar';
import Posting from './Posting'
import Container from 'react-bootstrap/Container'; 
import Col from 'react-bootstrap/Col'; 
import Row from 'react-bootstrap/Row'; 
import Button from 'react-bootstrap/Button'
import * as Realm from 'realm-web'; 
import {Modal} from 'react-bootstrap'



function Listings() {


  const [booked, updateBooked] = useState(new Set()) 
  const [changed, updateChanged] = useState(0);
  const [list, updateList] = useState([]) 

  const [items, updateItems] = useState(new Map()); 

  const [submitted, updateSubmitted] = useState(false); 
 
  const [climatePreference, updateClimatePreference] = useState(false); 
  const [driveUpAccess, updateDriveUpAccess] = useState(false); 

  const [reRender, updateReRender] = useState(false); 




  /* MONGODB REAL TIME UPDATE START */

  const app = new Realm.App({id: "application-0-odmvx"})
  const [user, setUser] = useState(); 
  const [events, setEvents] = useState([]); 




  function getDistance(id) {

   var s = fetch("/getDistance", { 
      headers: {"Content-Type" : "application/json"}, 
      credentials: 'include',
      withCredentials: true,
      method: "POST", 
      body: JSON.stringify({"id": id})
    }).then((response) => { 
      return response.json(); 
    }).then((data) => { 
      
      return data.foundItems;
    })

    return s

    
    
  }

  useEffect(() => {
    fetch("/api/preferences", { 
      headers: {"Content-Type" : "application/json"}, 
      credentials: 'include',
      withCredentials: true,   
    }).then((response) => {
      return response.json(); 
    }).then((data) => { 
      updateClimatePreference(data.climateControl)
      updateDriveUpAccess(data.driveUpAccess)

    })
    

  }, [])

  


  



    
  useEffect(() => { 
        
      
      const login = async () => {
        // Authenticate anonymously into App Services, asynchronous function
        const  user = await  app.logIn(Realm.Credentials.anonymous());
        
        setUser(user);
        
        const  mongodb = app.currentUser.mongoClient("mongodb-atlas");

        const  collection = mongodb.db("luggagefree").collection("items");
        

        for  await (const  change  of  collection.watch()) {
            setEvents(events  => [...events, change]);
            updateList([...list, change]) 
        }  
      
    }
  
    login();
  }, []) 



  useEffect(() => { 
    
    
    if (list.length > 0) { 
      

      var change = list[list.length - 1]; 
            


     if (change.operationType === "delete") { 
      items.delete(change.documentKey._id.toString())
      updateItems(new Map(items))

    } else if (change.operationType === "update") { 
      if (Object.keys(change.updateDescription.updatedFields).length === 1 && change.updateDescription.updatedFields.capacity !== undefined && change.updateDescription.updatedFields.capacity > 0) { 
        var el = items.get(change.documentKey._id.toString())
        if (el) {  /* to prevent race conditions */
          el.capacity = change.updateDescription.updatedFields.capacity;
          updateItems(new Map(items))
        }

      } 
    } else if (change.operationType === "insert") { 

      getDistance(change.documentKey._id).then((data) => { 
      

      el = {} 
      el.email =  [change.fullDocument.email, change.fullDocument.first, change.fullDocument.last, data[0], data[1]]
      el.price =  change.fullDocument.price; 
      el.capacity = change.fullDocument.capacity; 
      el.location = change.fullDocument.address; 
      el.startDate = change.fullDocument.startDate; 
      el.endDate = change.fullDocument.endDate; 
      el.driveUpAccess = change.fullDocument.driveUpAccess; 
      el.climateControlled = change.fullDocument.climateControlled;

      
      items.set(change.documentKey._id.toString(), el)
      
      
      //var mapAsc = new Map([...items.entries()].sort((a, b) => Number(a[1].email[4].split("s")[0]) - Number(b[1].email[4].split("s")[0]))) 
      
      //updateItems(mapAsc); 

      updateReRender(!reRender) 


      
      })

    }
  }

  }, [list])





  const navigate = useNavigate() 
  
  /* On The First Render Of the Page, Just Update The Entire Page State Does Not Persist Across Refresh */ 
  useEffect(() => {

    console.log('we are here...')
    /* Here We Are Essentially Just Implementing the Schema Database */ 
   
    const items2 = new Map()
    

    fetch("/api/listings", {
      headers: {"Content-Type": "application/json",
      "Access-Control-Allow-Credentials": 'true'},
      credentials: 'include',
      withCredentials: true}).then((response) => {
      return response.json()
    }).then(data => {

      if (data['message'] === "FAILURE") { 
        navigate('/')
      } else {
        

        var emailAndPrices = data['foundItems']


        for (let i = 0; i < emailAndPrices.length; i++) 
        { 

          var element = {} 
          element.email = [emailAndPrices[i]["email"], emailAndPrices[i]["first"], emailAndPrices[i]["last"], data.data[i][0], data.data[i][1]]
          element.price = emailAndPrices[i]["price"]
          element.location = emailAndPrices[i]["address"]
          element.capacity = emailAndPrices[i]["capacity"]
          element.startDate = emailAndPrices[i]["startDate"]
          element.endDate = emailAndPrices[i]["endDate"]
          element.climateControlled = emailAndPrices[i]["climateControlled"]
          element.driveUpAccess = emailAndPrices[i]["driveUpAccess"]
          updateItems(new Map(items2.set(emailAndPrices[i]._id, element)))
          

        }
      }
    })
  }, [navigate, submitted, reRender])
  
  
  useEffect(() => {
    fetch("/api/getBoughtListings", {
      headers: {"Content-Type": "application/json",
      "Access-Control-Allow-Credentials": 'true'},
      credentials: 'include',
      withCredentials: true})
      .then((response) => {
      return response.json()
    }).then(data => {
      updateBooked(new Set(data.bookedEmails)); 
    })
  }, [])

  function handlePreferences(event) {
    setShow(true)  


  }

  const [show, setShow] = useState(false) 

  const handleEsc = () => { 
    setShow(false); 
  }


  function handleClose() { 


    fetch("/api/preferences", { 
      method: "POST",
      headers: {"Content-Type": "application/json"},
      credentials: 'include',
      body: JSON.stringify({climatePreference: climatePreference, driveUpAccess: driveUpAccess})
      
      }).then((response) => { 
        //wait for database to update
        setTimeout(
          function () { updateSubmitted(!submitted);
        setShow(false) }, 1000)


      }).catch((err) => { 
        console.log(err) 
      })

    
    
    

  }

  function handleClimateControlled(event) {  updateClimatePreference(!climatePreference); }

  function handleDriveUpAccess(event) { updateDriveUpAccess(!driveUpAccess); }


  function handleReset(event) { 
    
      fetch("/api/reset", {
        headers: {"Content-Type": "application/json",
        "Access-Control-Allow-Credentials": 'true'},
        credentials: 'include',
        withCredentials: true
      }).then((data) => { 
        updateClimatePreference(false); 
        updateDriveUpAccess(false); 
        updateSubmitted(!submitted)
        setShow(false);  
      }).catch((err) => { 
        console.log(err) 
      })

    }


  return ( 
   
    <div>
      <Navigation/>
      <h1 style = {{textAlign: "center", marginTop: "1%"}} class = "display-4">Browse Listings</h1>
      <p class = "small" style = {{textAlign: "center", marginTop: "1%"}}> It might take a minute or two to load the listings, so don't leave/refresh the page! </p>
      <Container style = {{textAlign: "right"}}>
        <Button variant = "outline-dark" onClick = {handlePreferences}>🔗 Preferences 🔗</Button>
      </Container>
      <Modal show={show} onHide={handleEsc}>
        <Modal.Header closeButton>
          <Modal.Title>📝 Set Your Preferences</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <label></label>
        🤖 Our ML model uses your preferences to generate the order of the listings. If you would like to specify these preferences and tune the model, please do so below! <br/> <br/>
        <label>🌤️ Climate Control <input checked = {climatePreference} onChange = {handleClimateControlled} type = "checkbox"></input></label> <br></br>
        <label>🏎️ Drive Up Access <input checked = {driveUpAccess} onChange = {handleDriveUpAccess} type = "checkbox"></input></label> <br></br>

        </Modal.Body>
        <Modal.Footer>
          If you don't see a feature yet, don't worry; our team is working hard to get additional features and a filter system out, so stay tuned!
        <Button variant="btn btn-outline-dark" onClick={handleReset}>
              Reset Preferences
          </Button>
          <Button variant="success" onClick={handleClose}>
              Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Container style = {{marginTop: "2%", gap : "2rem"}}>
        <Row style ={{gap: "2rem"}}>
        {
        Array.from(items).map(([e, value])=> <Col xs = "auto"><Posting climateControlled = {items.get(e).climateControlled} driveUpAccess = {items.get(e).driveUpAccess} changed = {changed} updateChanged = {updateChanged} time = {items.get(e).email[3]} email = {items.get(e).email[0]} first = {items.get(e).email[1]} last = {items.get(e).email[2]} price = {items.get(e).price} location = {items.get(e).location} capacity = {items.get(e).capacity} booked = {booked} startDate = {items.get(e).startDate} endDate = {items.get(e).endDate} ></Posting> </Col>)}
      
        </Row>
      </Container>
      <div>

      </div>
    </div> 
  )
}

export default Listings;
