import React, {useState, useEffect, useRef} from 'react';
import {useNavigate} from 'react-router-dom'
import {Button, Container, Row, Col} from 'react-bootstrap'
import note from './notes.png'
import feedback from './feedback.png'
import create from './create.png'
import book from './Book.png'
import dashboard from './dashboard.png'
import alarm from './alarm.png'
import { useSpring, animated } from '@react-spring/web'




function Login() { 

    function useIntersectionObserver(
        elementRef,
        { threshold = 0, root = null, rootMargin = '0%', freezeOnceVisible = false }
    ) {
        const [entry, setEntry] = useState();
    
        const frozen = entry?.isIntersecting && freezeOnceVisible;
    
        const updateEntry = ([entry]) => {
            setEntry(entry);
        };
    
        useEffect(() => {
            const node = elementRef?.current;
            const hasIOSupport = !!window.IntersectionObserver;
    
            if (!hasIOSupport || frozen || !node) return;
    
            const observerParams = { threshold, root, rootMargin };
            const observer = new IntersectionObserver(updateEntry, observerParams);
    
            observer.observe(node);
    
            return () => observer.disconnect();
        }, [elementRef, threshold, root, rootMargin, frozen]);
    
        return entry;
    }

    
    const triggerRef = useRef(); 

    const dataRef = useIntersectionObserver(triggerRef, {
        freezeOnceVisible: true
      });

    const triggerRef2 = useRef(); 
    const dataRef2 = useIntersectionObserver(triggerRef2, {
        freezeOnceVisible: true
      });


    const triggerRef3 = useRef(); 
    const dataRef3 = useIntersectionObserver(triggerRef3, {
          freezeOnceVisible: true
    });

    const triggerRef4 = useRef(); 
    const dataRef4 = useIntersectionObserver(triggerRef4, {
          freezeOnceVisible: true
    });




    

    const navigate = useNavigate()


    const headerStyle2 = useSpring({
        config: {duration:  500}, 
        from: {marginTop: "130px"}, 
        to: { 
            marginTop: dataRef2?.isIntersecting ? "0px" : "130px"
        }
    });

    const headerStyle3 = useSpring({
        config: {duration:  1000}, 
        from: {marginTop: "100px"}, 
        to: { 
            marginTop: dataRef3?.isIntersecting ? "0px" : "100px"
        }
    });


    const headerStyle4 = useSpring({
        config: {duration:  1000}, 
        from: {marginTop: "100px"}, 
        to: { 
            marginTop: dataRef4?.isIntersecting ? "0px" : "100px"
        }
    });
    

    const headerStyle = useSpring({
        config: {duration:  500}, 
        from: {marginTop: "130px"}, 
        to: { 
            
            marginTop: dataRef?.isIntersecting ? "0px" : "130px"
        }
    });
    
    useEffect(() => { 
       fetch("/api/login", {
       headers: {"Content-Type": "application/json"}, 
       credentials: 'include',
       withCredentials: true 
    }).then((response) => {
        return response.json()
    }).then(data => {
        console.log('data here is...')
        if (data.SUCCESS === "true") { 
            navigate('/home')
        } else {
            
        }
    }).catch(err => { 
        console.log(err) 
    })

    }, [navigate])


    function signUpWithGoogle(event) { window.open("https://www.api.luggagefree.app/auth/google", "_self"); }


    console.log(dataRef)
    

    return ( 
            
        <div style = {{textAlign: "center"}}>
            
            <animated.div style = {headerStyle4}>
            <h1 style = {{marginTop: "5%", textAlign: "center"}} class = "display-4">Welcome to Luggage Free</h1>
            </animated.div>
            <div ref = {triggerRef4}></div>

            <Container>

                <animated.div style = {headerStyle3}>
                <hr style = {{marginTop: "5%"}}></hr>

                        <Row style = {{"marginTop": "15%"}} className = "justify-content-center">
                            <Col xs = "12">
                                <h1 style = {{"marginTop": "1.5%"}}>🧳 Store your luggage, host your property - all in one place 🧳</h1>
                            </Col>
                        </Row>

                        <Row className = "justify-content-center">
                            <Col xs = "12">
                                <h5 style = {{marginTop: "2%"}}>🏫 Luggage Free is the all-in-one application for college students to store their luggage when they need to. We help foster the community too - students can also post their places as places to store. 🏫</h5>
                            </Col>
                        </Row>

                        <Row className = "justify-content-center" xs = "auto" style = {{"marginTop" : "2%"}}>
                            <Button size = "lg" variant = "outline-dark" onClick = {signUpWithGoogle}>Start Here</Button>
                         </Row>
                         <Row className = "justify-content-center" xs = "10" style = {{"marginTop" : "1%"}}>
                            <p>⚠️ Please login with your <b>.edu</b> email (make sure you aren't logged into google with a non .edu email either) Otherwise, you won't be able to login. ⚠️</p>
                        </Row>
                
                </animated.div>
                <div ref = {triggerRef3}></div>
         

                        <Row style = {{marginTop: "10%"}} >
                            <hr></hr>
                            <h2 style = {{marginTop: "5%", textAlign: "left"}}>👋 What we offer...</h2>

                            <animated.div style = {headerStyle}>
                            <Row style = {{textAlign: "center", marginTop: "10%"}} className = "justify-content-center">

                            

                                <Col xs = "4" >
                                    <h3>Customizable Posting & Booking System</h3>
                                </Col>
                                <Col xs = "4">
                                    <h3>Live Updates</h3>
                                </Col>
                                <Col xs = "4">
                                    <h3>Personalized Recommendation System</h3>
                                </Col>
                            </Row>
                            </animated.div>
                            <div ref = {triggerRef}></div>
                            <Row>
                            


                            <Col xs = "4">
                                <p>Our website offers a wide variety of features to customize postings - everything <strong>from your name & autocomplete location features to whether the place is climate controlled. </strong> Use the interactive dashboard & slick listings page to easily book posting.</p>
                            </Col>

                            <Col xs = "4">
                                <p>Anytime a posting appears, you can <strong>instantly</strong> see the posting - no refresh required. Same goes for the dashboard page, easily see with real time updates who you have booked & who has booked your posting </p>


                            </Col>
                            
                            <Col xs = "4">
                                <p>Our <strong> ML </strong> algorithm lets you specify preferences to sort the postings you would like first - ensuring this app is customized <strong>for  you.</strong></p>
                            
                            </Col>
                            </Row>
                            
                            <Row style = {{marginTop: "3%"}}>
                                
                                <Col xs = "4">
                                    <img  width = "150" height = "auto" src = {note}/>
                                </Col>
                                <Col xs = "4">
                                    <img width = "150" height = "auto" src = {alarm}/>
                                </Col>
                                <Col xs = "4">
                                    <img width = "160"  height = "auto" src = {feedback}></img>
                                </Col>
                            </Row>



                            <Row style = {{marginTop: "15%"}}>
                            <hr></hr>
                            <animated.div style = {headerStyle2}>
                                <h2 style = {{marginTop: "5%", textAlign: "right"}}>...Take a Look at Some of Our Features 🔥 </h2>
                            </animated.div>
                            <div ref = {triggerRef2}></div>
                                <Row style= {{marginTop: "2%"}}>
                                    <img style = {{"overflow": "hidden", "border": "solid", "borderRadius":  "30px", "margin": "0", "padding": "0" }} src = {dashboard}></img>
                                </Row>
                                <Row style = {{marginTop: "10%"}}>
                                    <Col xs = "6">
                                        <img style = {{"border": "solid", "border-radius": "30px", "margin" : "0", "padding": "0"}} height = "auto" width = "100%" src = {create}></img>
                                    </Col>
                                    <Col xs = "6">
                                        <img style = {{"border": "solid", "border-radius": "30px",  "margin" : "0", "padding": "0"}} height = "auto" width = "100%" src = {book}></img>
                                    </Col>
                                </Row>
             

                            </Row> 


                           
                            <Row style = {{"marginTop": "10%"}}className = "justify-content-center" xs = "12">

                            <hr></hr>
                                <Col xs = "auto">
                                    <Button style = {{"marginTop" : "10%"}}size = "lg" variant = "outline-dark" onClick = {signUpWithGoogle}>Start Here</Button>
                                </Col>
                            </Row>
          

                            




                        </Row>
                                        <br/>
                            <p>Made by <a href = "https://www.linkedin.com/in/karan-majithia/"> Karan Majithia </a></p>
                            




            </Container>
          
        </div>
    )
}
export default Login; 