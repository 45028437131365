import { useState, useEffect, useRef } from 'react';
import {Button, Modal, Form, FormGroup} from 'react-bootstrap';
import 'react-day-picker/dist/style.css';
import {DayPicker} from 'react-day-picker'; 


function PopUp(props) {

  const [show, setShow] = useState(false);
  const [email, setEmail] = useState(""); 
  

  const [booked, updateBooked] = useState(false); 

  const handleEsc = () => { 
    setShow(false); 
  }

  const [range, setRange] = useState(undefined); 


  const handleClose = () => {
    
    if (range !== undefined && range.from !== undefined && range.to !== undefined) { 
      updateBooked(true); 
      setShow(false);
      fetch("/api/book", { 
        method: "POST", 
        headers: {"Content-Type": "application/json"}, 
        body: JSON.stringify({"start": range.from.toLocaleString().split(',')[0], "end" : range.to.toLocaleString().split(',')[0], "email" : props.email, "price" : props.price, "address" : props.address,
        "climateControlled" : props.climateControlled, "driveUpAccess": props.driveUpAccess}),
        credentials: 'include',
        withCredentials: true
     }).then((data) => { 
        setTimeout(function() {
          props.updateChanged(props.changed + 1);
          
        }, 1000)

     })
    }
  }

  useEffect(() => {
    fetch("/api/email", {
      headers: {"Content-Type": "application/json",
      "Access-Control-Allow-Credentials": 'true'},
      credentials: 'include',
      withCredentials: true}).then((response) => {
      return response.json()
    }).then(data => {
        setEmail(data.email) }); 
    }, [])


  
 
  const handleShow = () => setShow(true);


  function filter(date) {
    return (props.minDate > date) || (date > props.maxDate);
  }

  return (
    <div>

      <Button variant="link" onClick={handleShow} disabled = { ( booked || (email === props.email) || (props.booked.has(props.email))) ? true : false }>
        View Details
      </Button>
      
      <Modal show={show} onHide={handleEsc}>
        <Modal.Header closeButton>
          <Modal.Title>Book Listing</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        
          
        📍 {props.address} <br/>
        {props.climateControlled && "❄️ Climate Controlled"}
        {!props.climateControlled && "🚫 Not Climate Controlled"} 
        <br/>
        {props.driveUpAccess && "🚛 Drive Up Access"}
        {!props.driveUpAccess && "🚶🏻‍♂️ No Drive Up Access"} 
        <br/>
        <br/>
        🧳 <strong>Selecting</strong> a date range is required (via cursor), otherwise, this popup won't close on your submission; don't worry about writing down this information, everything will be available on the homepage!
        <br/>
        <br/>

          <DayPicker
            mode = "range"
            selected = {range} 
            onSelect = {setRange}
            disabled = {filter}
          />

      
        </Modal.Body>
        <Modal.Footer>
          
          <Button variant="danger" onClick={handleEsc}>
            Cancel
          </Button>
          <Button variant="success" onClick={handleClose}>
            Submit
          </Button>

        </Modal.Footer>
      </Modal>

    </div>
  );
}

export default PopUp;